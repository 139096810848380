<template>
  <div>
    <LotsSupplying label="Lotes abastecidos"></LotsSupplying>
  </div>
</template>

<script>

import LotsSupplying from './LotsSupplying.vue'

export default {
   name: "LotsSupplyingG",
  components: { LotsSupplying },
  props: {},
  data: () => ({}),
}
</script>